// import hljs from 'highlight.js';
import 'highlight.js/styles/atom-one-dark.css';
import { Quill } from 'react-quill';
import BlotFormatter from 'quill-blot-formatter';
import 'quill/dist/quill.snow.css';
import './editor.css';
import quillEmoji from 'quill-emoji';
import 'quill-emoji/dist/quill-emoji.css';
import ImageUploader from "quill-image-uploader";
import "quill-image-uploader/dist/quill.imageUploader.min.css";
import axios from 'axios';
import { ImageActions } from '@xeger/quill-image-actions';
import { ImageFormats } from '@xeger/quill-image-formats';

Quill.register('modules/imageActions', ImageActions);
Quill.register('modules/imageFormats', ImageFormats);



// Add custom fonts styles to whitelist and register them
let fontStyleArray = [
	'Cooper-Book',
	'Cooper-Medium',
	'Cooper-Semibold',
	'Cooper-Bold',
	'Cooper-Heavy',
	'Cooper-LightItalic',
];
const Font = Quill.import('attributors/style/font');
Font.whitelist = [...fontStyleArray];
Quill.register(Font, true);

//add custom fonts sizes
const fontSizeArr = [
	'8px',
	'10px',
	'12px',
	'14px',
	'16px',
	'18px',
	'20px',
	'24px',
	"26px",
	'28px',
	'30px',
	'32px',
	'34px',
	'36px',
	'38px',
	'40px',
	'42px',
	'54px',
	'68px',
	'84px',
	'98px',
];
let Size = Quill.import('attributors/style/size');
Size.whitelist = fontSizeArr;
Quill.register(Size, true);

// Resize and align images
Quill.register('modules/blotFormatter', BlotFormatter);

//add emojis
Quill.register(
	{
		'formats/emoji': quillEmoji.EmojiBlot,
		'modules/emoji-shortname': quillEmoji.ShortNameEmoji,
		'modules/emoji-toolbar': quillEmoji.ToolbarEmoji,
		'modules/emoji-textarea': quillEmoji.TextAreaEmoji,
	},
	true
);

//add lineHeight
let Parchment = Quill.import('parchment');
let lineHeightConfig = {
	scope: Parchment.Scope.INLINE,
	whitelist: [
		'1.0',
		'1.2',
		'1.5',
		'1.6',
		'1.8',
		'2.0',
		'2.4',
		'2.8',
		'3.0',
		'4.0',
		'5.0',
	],
};
let lineHeightClass = new Parchment.Attributor.Class(
	'lineheight',
	'ql-line-height',
	lineHeightConfig
);
let lineHeightStyle = new Parchment.Attributor.Style(
	'lineheight',
	'line-height',
	lineHeightConfig
);
Parchment.register(lineHeightClass);
Parchment.register(lineHeightStyle);

// undo and redo
let icons = Quill.import('ui/icons');
icons['undo'] = `<svg viewbox="0 0 18 18">
    <polygon class="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10"></polygon>
    <path class="ql-stroke" d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"></path>
  </svg>`;
icons['redo'] = `<svg viewbox="0 0 18 18">
    <polygon class="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10"></polygon>
    <path class="ql-stroke" d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"></path>
  </svg>`;

// Undo and redo functions for Custom Toolbar
function undoChange() {
	this.quill.history.undo();
}

function redoChange() {
	this.quill.history.redo();
}

let Link = Quill.import('formats/link');
let sanitizeLinkSuper = Link.sanitize;
Link.sanitize = function customSanitizeLinkInput(linkValueInput) {
	let val = linkValueInput;
	if (/^\w+:/.test(val) || val.startsWith('${')) {
		// do nothing, user is already using a custom protocol or a parameter value
		return val;
	} else if (!/^https?:/.test(val)) {
		// add missing http protocol to url
		if (val.startsWith('/')) {
			val = 'http:' + val;
		} else {
			val = 'http://' + val;
		}
	}
	return sanitizeLinkSuper.call(this, val);
};

Quill.register(Link, true);

//toolbar
let toolbarOptions = [
	[{ font: [...fontStyleArray] }, { header: [] }],
	[{ size: [...fontSizeArr] }],
	[
		{
			lineheight: [
				'1.0',
				'1.2',
				'1.5',
				'1.6',
				'1.8',
				'2.0',
				'2.4',
				'2.8',
				'3.0',
				'4.0',
				'5.0',
			],
		},
	],
	['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block'],
	[{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
	[
		{ align: '' },
		{ align: 'right' },
		{ align: 'center' },
		{ align: 'justify' },
	],
	[{ color: [] }, { background: [] }],
	['emoji'],
	['link', 'image'],
	['clean'],
	['undo'],
	['redo'],
];

export const formats= [
	"align",
	"background",
	"blockquote",
	"bold",
	"code-block",
	"color",
	"float",
	"font",
	"header",
	"height",
	"image",
	"italic",
	"link",
	"script",
	"strike",
	"size",
	"underline",
	"width"
  ];


//image upload
Quill.register("modules/imageUploader", ImageUploader);

//modules
export const modules = {
	toolbar: {
		container: toolbarOptions,
		scrollingContainer: '#editorcontainer',
		bounds: '#editorcontainer',
		sticky_toolbar: true,
		handlers: {
			undo: undoChange,
			redo: redoChange,
		},
	},
	history: {
		delay: 500,
		maxStack: 100,
		userOnly: true,
	},
	'emoji-toolbar': true,
	'emoji-textarea': false,
	'emoji-shortname': true,
	// blotFormatter: {},
	imageActions: {},
    imageFormats: {},  
	// Add upload function
	imageUploader: {
		upload: async (file) => {
			if (!file) return;
		  
			const formData = new FormData();
			formData.append('file', file);
			
			return new Promise((resolve, reject) => {
				axios.post('https://server.co-opts.net/upload', formData, {
					headers: {
						'Content-Type': 'image/jpeg',
					},
				}).then((response) => {
					resolve(response.data.file);
				}).catch((error) => {
					reject('Upload failed');
					console.error('Error uploading file:', error);
				});
			})
		  
			
		  }
		  		  
},


};

// Quill Toolbar component
export const Toolbar = () => {
	// Use useMemo to memoize the modules object

	// Your component code here, using memoizedModules
	return <></>;
};

export default Toolbar;

import React from "react";

const FormLabel = ({number, text}) => {
  return (
    <div className="flex items-center gap-x-2 pb-4">
      <span className="h-5 w-5 p-2 text-xs flex items-center justify-center text-white bg-primary-blue rounded-full">
        {number}
      </span>
      <p className="font-medium text-sm">{text}</p>
    </div>
  );
};

export default FormLabel;
